import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { TrailguidePlugin, Gallery, Thumb } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The `}<a parentName="p" {...{
        "href": "http://www.trailtrophy.eu"
      }}>{`Trail Trophy Enduro Series`}</a>{` takes place in
5 different venues in Switzerland, Italy and Germany. Over 300 riders came
together for the first race in St. Andreasberg, Germany and enjoyed the
awesome spring weather with not a single cloud in the sky.`}</p>
    <Gallery mdxType="Gallery">
  <Thumb src="news/ttstandreasberg5.jpg" mdxType="Thumb">The start and finish area</Thumb>
  <Thumb src="news/ttstandreasberg1.jpg" mdxType="Thumb">All maps and descriptions</Thumb>
  <Thumb src="news/ttstandreasberg3.jpg" mdxType="Thumb">
    Some race action captured by Dustin Drechsler
  </Thumb>
  <Thumb src="news/ttstandreasberg4.jpg" mdxType="Thumb">
    Some race action captured by Dustin Drechsler
  </Thumb>
  <Thumb src="news/ttstandreasberg2.jpg" mdxType="Thumb">
    Some race action captured by Dustin Drechsler
  </Thumb>
  <Thumb src="news/ttstandreasberg8.jpg" mdxType="Thumb">Open barbecue in the evening</Thumb>
    </Gallery>
    <br />
    <p><a parentName="p" {...{
        "href": "https://www.bike-projects.com"
      }}>{`Bike Projects`}</a>{` did a great job and created a
great racecourse, even built some fresh tracks especially for the event.`}</p>
    <br />
    <p>{`This year the riders could see the race course with all information about the
stages and the event on Trailguide. Scanning a QR-code with their smart phone
opened all the event specific information and each rider could take it with
them on their smartphone.`}</p>
    <br />
    <p>{`The best stages will stay online so everyone can ride the race course
afterwards in a relaxed atmosphere with their friends. Like this, the
destination and other bikers benefit from the event also longer than one
weekend.`}</p>
    <br />
    <p>{`These interactive maps are also available as interactive plugins to your
website as shown below.`}</p>
    <TrailguidePlugin content="/xK" mdxType="TrailguidePlugin" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      